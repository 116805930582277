<template>
    <div class="HomeProduct">    
        <div style="text-align:center;margin-left:auto;margin-right:auto;width:100%;">
            <div style="width:100%;margin-top:10px;">
                <div style="height:96px;">
                    <el-image fit="scale-down" :src='require("@/images/256/dku.00002.png")' style="height:96px;"/>
                </div>                          
            </div>
            <div style="min-width:400px;">
                <div style="margin-top:10px;">
                    <div style="font-size:24px;line-height:30px;color:#000099;">
                        <span >重庆德数云健康科技有限公司</span>
                    </div>
                    <div style="line-height:20px;font-size:12px;color:#000099;">
                        <span >ChongQing DigitHealthCloud Technology Co.,Ltd.</span>
                    </div>                  
                </div>
            </div>            
        </div>
        <div class="product">
            <div v-for="row in products" :key="row.Key" class="row">
                <div style="color:rgb(40,40,40);font-weight:600;font-size:18px;">
                    <span>{{row.Title}}：</span>
                </div>
                <div v-for="item in row.texts" :key="item.Key">
                    <div style="width:900px;font-size:14px;color:rgb(40,40,40);">
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.Content}}</p>
                    </div>
                </div>

            </div>
            <div style="width:100%;height:500px;margin-top:100px;">
                <el-image fit="scale-down" :src="require('@/images/free/dku.00110.png')" style="width:100%;height:100%;"/>
            </div>   
            <div style="line-height:50px;font-size:22px;color:#000099;text-align:center;margin-top:150px;">
                <span >德健康之数成健康之美</span>
            </div>                       
        </div>                               
    </div>
</template>

<script>      
    import Macmonitor from "@/views/template/Macmonitor";  
    export default {    
        //##############################################################################################################################
        components:
        {
            Macmonitor
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Title:'经营范围',
                        texts:[
                            {Content:'一般项目:技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广;软件开发;软件销售;远程健康管理股务;健康咨询服务(不含诊疗服务);软件外包服务;信息系统运行维护服务;信息技术咨询眼务信息系统集成服务;网络与信息安全软件开发;云计算设备销售;数字技术服务;办公设备耗材销售;计算机软硬件及辅助设备批发;计算机软硬件及辅助设备零售;计算机系统服务;电子产品销售，(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)。'},
                            {Content:'本公司是以软件开发、软件销售、技术服务、技术开发、技术咨询为主的IT行业公司，专注从事和医疗行业的相关的应用软件研发和销售，公司的综指：技术不是软件产品的重点，实用性、易用性才是软件的魂，强点的是整体的体验效果，我们卖的不只是产品，更多的是服务。'}
                        ]
                    },                                     
                    {Key:3,Title:'奋斗目标',
                        texts:[
                            {Content:'公司致力于长期打造医疗健康管理生态体系，首先从医疗机构的门诊医疗系统、住院医疗系统、社区医院以及体检机构的体检业务系统、医养机构的医养系统、患者个人穿戴设备 等场景中采集到必要的健康数据，然后经过风险评估进行风险人群识别为低风险人群、一般风险人群、高风险人群、慢病患者人群。针对不同的人群进行不同的健康干预指导、最终打造出适应不同风险人群的健康商城，形成一个集人寿保险、120、快速公司、周边药店等为一体的健康生态链。'},
                        ]
                    },                                           
                ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1060px;       
        margin-top:50px; 
        // margin-left:auto;
        // margin-right:auto; 
        // display: flex;      
        // border-top:1px solid #0072C6;
    }
    .row{
        width: 800px;        
        margin-left:100px;
        // margin-right:200px; 
        //display: flex;     
        border-top:0px solid #0072C6;      
    }
    .productcard{
        height:700px;
        width:1030px;
        border:0px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        margin-left:7px;
        margin-top:30px;
        padding:5px;
        border-radius: 4px;         
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:600px;
        margin-top:0px;
    }
    .producttitle{
        text-align: center;
        font-size:14px;
        line-height:30px;
        font-weight: 600;
        color:rgb(80,80,80);
        
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
</style>