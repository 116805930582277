<template>
    <div class="ExaminePerson">    
        <div style="height:60px;display:flex;">
            <div style="width:48px;height:48px;">
                <el-image fit="scale-down" :src="require('@/images/48/dku.02090.png')"/>
            </div>
            <div style="font-size:22px;font-weight:500;line-height:48px;margin-left:10px;color:#000099;">
                <span>个人健管中心</span>
            </div>            
        </div>  
        <div class="product">
            <div style="font-size:14px;font-weight:500;line-height:22px;color:rgb(50,50,50);">
                <ul>
                <li><span>个人体检预约登记，包括预约体检日期、选体检套餐、自选体检项目、自助缴费等</span></li>
                <li><span>针对单位体检，允许受检个人自己从体检中心安排可预约的时间段中选择具体时间</span></li>
                <li><span>直接在手机微信端自助签到，无须排队等候</span></li>
                <li><span>检中推送各待检项目等待排队时间，可以采纳平台推荐顺序体检(根据时间最短、空腹优先、区域优先、身份等)，同时支持人为直接选择优先体检项目</span></li>
                <li><span>体检完成后体检报告的在线查阅并允许下载电子版报告（如PDF报告附件）</span></li>
                <li><span>通过留言、电话、互联网聊天等形式对报告中有疑问的地方进行咨询，允许引用报告文本内容或切图内容</span></li>
                <li><span>从检前开始到检中、检后三个环节推送不同的消息内容，检前包括体检注意事项、时间提醒、天气提醒、车位提醒等等；检中包括体检进度、下一体检项目推荐、危急报警等；检后包括复查事项等，这一环节是体现受检者直接体验的关键</span></li>
                </ul> 
            </div>            
            <div style="display:flex;margin-top:20px;font-size:9px;">
                <div v-for="item in products" :key="item.Key">
                    <div style="width:256px;margin:5px;box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);" >
                        <el-image fit="scale-down" :src="require('@/images/free/'+`${item.Icon}`+'.png')" style="width:100%;height:100%;"/>
                    </div>                    
                    <div style="width:256px;text-align:center;font-size:14px;">
                        <span>{{item.Title}}</span>
                    </div>
                    <!-- <div>
                        <ul>
                            <li v-for="txt in item.texts" :key="txt.Key">
                                <span style="color:rgb(100,100,100);">{{txt.Title}}</span>
                            </li>                 
                        </ul> 
                    </div>                                                             -->
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:
                [
                    {Key:1,Title:'主页',Icon:'dku.00111',texts:[
                            {Key:1,Title:'体检套餐查阅'},
                            {Key:2,Title:'消息接收快捷入口'},
                            {Key:3,Title:'快捷功能入口'}
                            ]},
                    {Key:2,Title:'体检',Icon:'dku.00112',texts:[
                        {Key:1,Title:'当前体检项目进度及排队状况'},
                        {Key:2,Title:'最近历次体检索引'},
                        {Key:3,Title:'当前体检快捷功能入口'}
                        ]},
                    {Key:3,Title:'健康',Icon:'dku.00113',texts:[
                        {Key:1,Title:'受检者检前问卷'},
                        {Key:2,Title:'根据问卷推荐体检项目'},
                        {Key:3,Title:'人为调整体检项目'}
                        ]},
                    {Key:4,Title:'商城',Icon:'dku.00114',texts:[
                        {Key:1,Title:'受检者检前问卷'},
                        {Key:2,Title:'根据问卷推荐体检项目'},
                        {Key:3,Title:'人为调整体检项目'}
                        ]}
                ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1060px;        
        // margin-left:auto;
        // margin-right:auto; 
        // display: flex;      
        border-top:1px solid #0072C6;
    }
    .product ul {
        display: flex;
        flex-wrap: wrap;
    }
    .product li {
        align-items: center;
        flex-basis: 100%;    
    }       
    .row{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;     
        border-top:0px solid #0072C6;      
    }
    .productcard{
        height:280px;
        width:330px;
        border:0px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        margin-left:7px;
        margin-top:30px;
        padding:5px;
        border-radius: 4px;
        display: flex;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        background-color: rgba(0, 0, 255, 0.2); 
        
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:128px;
        height:277px;
        margin-top:0px;
    }
    .producttitle{
        text-align: center;
        font-size:14px;
        line-height:30px;
        font-weight: 600;
        color:rgb(80,80,80);
        
    }
</style>