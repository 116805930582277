<template>
    <div class="Macmonitor">        
        <div :style="contentstyle">
            <el-image fit="fill" :src="image" style="width:100%;height:100%;"/>
        </div>
    </div>
</template>

<script>    
    import FrameContainer from "@/components/base/frame/FrameContainer";     
    export default {            
        //##############################################################################################################################
        components:
        {
            FrameContainer
        },    
        //##############################################################################################################################
        props:
        {
            left:{type:Number,default:19},
            top:{type:Number,default:21},
            width:{type:Number,default:460},
            height:{type:Number,default:272},
            image:{type:String,default:""}
        },        
        computed:
        {
            contentstyle()
            {
                var style="position:relative;left:"+this.left+"px;top:"+this.top+"px;width:"+this.width+"px;height:"+this.height+"px;";
                return(style);
            }
        },
        //############################################################################################################################## 
        data(){
            return{   
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .Macmonitor{        
        height:100%;
        width:100%;
        margin-left:0px !important;
        margin-right:0px !important;  
        background-image:url("../../images/free/dku.00100.png");         
        background-size: 100% 100%;
        background-repeat: no-repeat;                           
    }
    .content{
        position:relative;
        left:19px;
        top:21px;
        width:460px;
        height:272px;
        // background-color: blue;
    }
</style>