<template>
    <div class="Home">
        <Scrollbar>
            <div class="content">
                <div>
                    <div v-for="block in blocks" :key="block.Key">
                        <div v-if="block.Key==1" :style="block.Style">
                            <AboutIntroduce/>
                        </div>                        
                    </div>
                </div>
            </div>     
            <div>
                <SimpleConcat/>
            </div>
        </Scrollbar>
    </div>
</template>

<script>    
    import Scrollbar from "@/components/base/Scrollbar";  
    import SimpleConcat from "@/views/template/SimpleConcat";  
    import AboutIntroduce from "@/views/about/AboutIntroduce";
    export default {            
        //##############################################################################################################################
        components:
        {
            Scrollbar,SimpleConcat,AboutIntroduce
        },    
        //############################################################################################################################## 
        data(){
            return{   
                blocks:[                    
                    {Key:1,Title:'概述',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:0px;padding-top:10px;padding-bottom:60px;"},
                ],                        
               carouselitems:[
                    {Key:'1',Title:'单位'},
                    {Key:'2',Title:'职业'},                
                    {Key:'3',Title:'职业'}, 
               ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .Home{
        height:100%;
        width:100%;
        background-color: rgba(255, 255, 255, 0.1); 
        padding-top:5px;
    }
    .content{
        margin-left:auto;
        margin-right:auto;  
        width:1100px;
        text-align: left;             
    }
    .header{
        height: 400px;        
        text-align: center;                   
    }
</style>