<template>
    <div class="HomeProduct">    
        <div style="height:60px;display:flex;">
            <div style="width:48px;height:48px;">
                <el-image fit="scale-down" :src="require('@/images/48/dku.02613.png')"/>
            </div>
            <div style="font-size:22px;font-weight:500;line-height:48px;margin-left:10px;color:#000099;">
                <span>单位健管中心</span>
            </div>            
        </div>  
        <div class="product">
            <div v-for="row in products" :key="row.Key" class="row">
                <div style="display:flex;">
                    <div v-for="item in row.Items" :key="item.Key" class="productcard">
                        <div style="min-width:500px;">
                            <div class="producticon">
                                <Macmonitor style="width:500px;height:400px;"
                                    :left="19" 
                                    :top="21" 
                                    :width="460" 
                                    :height="272"
                                    :image="require('@/images/free/dku.00104.png')"
                                />
                            </div>
                        </div> 
                        <div style="width:540px;font-size:9px;line-height:25px;">
                            <ul>
                                <li v-for="txt in item.texts" :key="txt.Key">
                                    <span style="color:rgb(50,50,50);">{{txt.Title}}</span>                                    
                                </li>                 
                            </ul>  
                        </div>                       

                    </div>
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    import Macmonitor from "@/views/template/Macmonitor";  
    export default {            
        //##############################################################################################################################
        components:
        {
            Macmonitor
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Items:[
                                {Key:1,Title:'单位体检申请',Icon:'20308',texts:[
                                        {Key:1,Title:'体检申请预约，包括指定体检类型、体检人数、希望的结算策略（比如个人加项结算方式等）、体检合同的确认等'},
                                        {Key:2,Title:'由单位自己进行体检人员名单登记、人员分组设置及组体检套餐,备单完成后，由体检中心给出体检时间段及人数'},
                                        {Key:3,Title:'体检过程中可查询单位体检进度，包括未检、在检、已检人数，并支持向未检单位人员进行催检信息推送'},
                                        {Key:4,Title:'体检完成后体检报告的在线查阅并允许下载电子版报告（如pdf报告附件）,包括单位体检报告及单位人员的体检报告'}
                                        ]}
                                ]
                    }               
                ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1060px;        
        // margin-left:auto;
        // margin-right:auto; 
        // display: flex;      
        border-top:1px solid #0072C6;
    }
    .row{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;     
        border-top:0px solid #0072C6;      
    }
    .productcard{
        height:400px;
        width:1030px;
        border:0px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        margin-left:7px;
        margin-top:30px;
        padding:5px;
        border-radius: 4px;
        display: flex;        
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:400px;
        margin-top:0px;
    }
    .producttitle{
        text-align: center;
        font-size:14px;
        line-height:30px;
        font-weight: 600;
        color:rgb(80,80,80);
        
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
</style>