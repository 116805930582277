<template>
    <div class="HomeProduct">    
        <div style="height:60px;display:flex;">
            <div style="width:48px;height:48px;">
                <el-image fit="scale-down" :src="require('@/images/48/dku.02668.png')"/>
            </div>
            <div style="font-size:22px;font-weight:500;line-height:48px;margin-left:10px;color:#000099;">
                <span>适应多种体检类型业务</span>
            </div>            
        </div>  
        <div class="product">            
            <div style="border-top:1px solid #0072C6;display:flex;">
                <div v-for="item in products" :key="item.Key" class="productcard">
                    <div class="producticon">
                        <el-image fit="scale-down" :src="require('@/images/48/'+`${item.Icon}`+'.png')" style="width:100%;height:100%;"/>
                    </div>
                    <div class="producttitle">
                        <span>{{item.Title}}</span>
                    </div>
                </div>
            </div>            
        </div>                               
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Title:'普通健康体检',Icon:'dku.03023'},                
                    {Key:3,Title:'职业病健康体检',Icon:'dku.02113'},
                    {Key:4,Title:'从业健康体检',Icon:'dku.03057'},
                    {Key:5,Title:'中医体质辨识',Icon:'dku.02626'},                    
                ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;      
                 
    }
    .productcard{
        height:200px;
        width:255px;
        border:0px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        margin-top:30px;
        border-radius: 4px;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        background-image:url("../../images/free/dku.00121.jpg");  
        
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:48px;
        margin-top:50px;
    }
    .producttitle{
        text-align: center;
        font-size:16px;
        line-height:80px;
        font-weight: 500;
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
</style>