<template>
    <div class="HomeProduct">    
        <div style="height:60px;display:flex;">
            <div style="width:48px;height:48px;">
                <el-image fit="scale-down" :src="require('@/images/48/dku.03065.png')"/>
            </div>
            <div style="font-size:22px;font-weight:500;line-height:48px;margin-left:10px;color:#000099;">
                <span>典型应用</span>
            </div>            
        </div>  
        <div class="product">
            <div v-for="item in products" :key="item.Key" class="row">
                <div style="width:900px;text-align:left;font-size:14px;margin-left:160px;line-height:36px;">                    
                    <span style="margin-right:10px;">√</span>
                    <span>{{item.Title}}</span>
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Title:'院内HIS传染病多点监测预警上报系统'},
                    {Key:2,Title:'院内HIS & 江西省医疗机构远程医疗数据交互系统'},
                    {Key:3,Title:'院内HIS & 艾登病案首页质控数据交互系统'},
                    {Key:4,Title:'患者跌倒压疮管理记录系统'},
                    {Key:5,Title:'院内HIS & 卫医通绩效管理数据交互系统'},
                    {Key:6,Title:'院内HIS & 泰康人寿健保通数据交互系统'},
                    {Key:7,Title:'院内HIS & 卫健委DIP综合管理应用平台数据交互系统'},
                    {Key:8,Title:'医院病案质量监测上报系统（HQMS）'},
                    {Key:9,Title:'院内HIS & 天坦移动医护数据交互系统'},
                    {Key:10,Title:'院内HIS & 慧目眼科检查信息数据交互系统'},
                    {Key:11,Title:'院内HIS & 蓝网影像数据交互系统'},
                    {Key:12,Title:'院内HIS & 袋鼠体检管理数据交互系统'},
                    {Key:13,Title:'医院医保关键指标监测报警系统'},
                    {Key:14,Title:'职业病体检报告上报告系统'},
                    {Key:15,Title:'医保定点医药机构信息采集报告系统'},
                ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1060px;           
        border-top:1px solid #0072C6;
    }
    .row{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;     
        border-top:0px solid #0072C6;      
    }
    .productcard{
        height:400px;
        width:1030px;
        border:0px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        margin-left:7px;
        margin-top:30px;
        padding:5px;
        border-radius: 4px;
        display: flex;        
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:400px;
        margin-top:0px;
    }
    .producttitle{
        text-align: center;
        font-size:14px;
        line-height:30px;
        font-weight: 600;
        color:rgb(80,80,80);
        
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
</style>