<template>
    <div class="Home">
        <Scrollbar>
            <div class="content">
                <div>
                    <div v-for="block in blocks" :key="block.Key">
                        <div v-if="block.Key==1" :style="block.Style">
                            <DataExchangeSummary/>
                        </div>                        
                        <div v-else-if="block.Key==2" :style="block.Style">
                            <DataExchangeApplication/>
                        </div>
                    </div>
                </div>
            </div>     
            <div>
                <SimpleConcat/>
            </div>
        </Scrollbar>
    </div>
</template>

<script>    
    import FrameContainer from "@/components/base/frame/FrameContainer"; 
    import Scrollbar from "@/components/base/Scrollbar";  
    import SimpleConcat from "@/views/template/SimpleConcat";  
    import DataExchangeSummary from "@/views/dataexchange/DataExchangeSummary";
    import DataExchangeApplication from "@/views/dataexchange/DataExchangeApplication";    
    export default {            
        //##############################################################################################################################
        components:
        {
            FrameContainer,Scrollbar,SimpleConcat,DataExchangeSummary,DataExchangeApplication
        },    
        //############################################################################################################################## 
        data(){
            return{   
                blocks:[                    
                    {Key:1,Title:'概述',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:10px;padding-bottom:60px;"},
                    {Key:2,Title:'典型应用',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;display:flex;"},
                ],                        
               carouselitems:[
                    {Key:'1',Title:'单位'},
                    {Key:'2',Title:'职业'},                
                    {Key:'3',Title:'职业'}, 
               ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .Home{
        height:100%;
        width:100%;
        background-color: rgba(255, 255, 255, 0.1);  
        padding-top:5px;                 
    }
    .content{
        margin-left:auto;
        margin-right:auto;  
        width:1100px;
        text-align: left;     
    }
    .header{
        height: 400px;        
        text-align: center;                   
    }
</style>