<template>
    <div class="HomeProduct">                           
        <div style="height:60px;display:flex;">
            <div style="width:48px;height:48px;">
                <el-image fit="scale-down" :src="require('@/images/48/dku.02681.png')"/>
            </div>
            <div style="font-size:22px;font-weight:500;line-height:48px;margin-left:10px;color:#000099;">
                <span>健康体检中心产品解决方案</span>
            </div>            
        </div>  

        <div class="product">
            <div style="width:1060px;height:600px;display:flex;border-top:1px solid #0072C6;">

                <div style="height:589px;width:788px;margin-top:55px;">
                    <el-image fit="fill" :src="require('@/images/free/dku.00118.png')" style="width:100%;height:100%;border-radius:6px;"/>
                </div>
                
                <div class="text" style="width:440px;margin-top:35px;font-size:14px;color:rgba(0,0,0,1);line-height:30px;font-weight:500;padding:0px;color:black;">
                    <ul>
                        <li v-for="item in texts" :key="item.Key">
                            {{item.Title}}
                        </li>
                    </ul>                                                                        
                </div>                  
            </div>            
        </div>    
                                 
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Title:'健康体检中心',Icon:'20308'},                
                    {Key:3,Title:'个人健管中心',Icon:'200188'},
                    {Key:4,Title:'单位健管中心',Icon:'200229'},
                    {Key:5,Title:'产品管理中心',Icon:'20048'},
                    {Key:2,Title:'数据交换平台',Icon:'10091'},
                ],
                texts:[
                    {Key:1,Title:'主要由产品管理中心、数据交换平台、健康体检中心、个人健管中心、单位健管中心五部分产品组成，相互协调、各施其职，形成一个完整的闭环体系，其中数据交换平台、健康体检中心、个人健管中心、单位健管中心即是健康体检中心的辅助产品，同时也允许单独销售以配合其他三方体检产品。'},
                    {Key:2,Title:'产品管理中心负责装卸、升级自身及各业务产品以及登录帐户、功能授权、工作站、资源、导航样式、后台服务等管理，主要适用系统管理员。'},
                    {Key:3,Title:'数据交换平台负责三方产品和健康体检中心产品的数据交换（如和院内HIS、LIS、PACS系统的接口等）。'},
                    {Key:4,Title:'健康体检中心负责核心的体检业务操作及处理。'},
                    {Key:5,Title:'单位客户端使用单位健管中心进行单位体检的申请预约、单位人员登记、个人报告、单位报告的查阅下载等。'},
                    {Key:6,Title:'个人客户使用个人健管理中心手机端完成受检者登记、预约、缴费以及检中智能排队、检后体检报告、分析报告的查阅和下载、报告解读等。'},
                    {Key:7,Title:'全系产品采用B/S架构，有效减少了对客户端的要求及避免后续升级带来的麻烦，同时也保障了未来全面国产化的需求。'}
                ]
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .HomeProduct{
        background-color: transparent !important;
    }
    .product{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;       
    }
    .productcard{
        height:200px;
        width:200px;
        border:1px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        border-radius: 4px;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        background-color: white;
    }
    .producticon{
        width:100%;
        height:48px;
        margin-top:50px;
    }
    .producttitle{
        text-align: center;
        font-size:16px;
        line-height:80px;
        font-weight: 500;
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
    .text ul {
        display: flex;
        flex-wrap: wrap;
    }
    .text li {
        align-items: center;
        flex-basis: 100%;    
    }        
</style>


