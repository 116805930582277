<template>
    <div class="HomeProduct">                           
        <div style="height:60px;display:flex;">
            <div style="width:48px;height:48px;">
                <el-image fit="scale-down" :src="require('@/images/48/dku.00123.png')"/>
            </div>
            <div style="font-size:22px;font-weight:500;line-height:48px;margin-left:10px;color:#000099;">
                <span>受检者体检报告</span>
            </div>            
        </div>  

        <div class="product">           
            <div style="width:1060px;height:730px;border-top:1px solid #0072C6;display:flex;">
                <div style="height:712px;min-width:600px;margin-top:30px;margin-left:10px;">
                    <el-image fit="scale-down" :src="require('@/images/free/dku.00115.png')" style="width:100%;height:100%;border-radius:6px;"/>
                </div>
                <div style="height:712px;font-size:14px;font-weight:500;line-height:22px;color:#000099;margin-top:60px;min-width:200px;">
                    <ul>
                        <li v-for="txt in texts" :key="txt.Key">
                            <span style="color:rgb(50,50,50);">{{txt.Title}}</span>                                    
                        </li>                                              
                    </ul> 
                </div>                   
            </div>
        </div>    
                                 
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
                texts:[
                    {Key:1,Title:'真正完整的体检报告'},
                    {Key:2,Title:'一般临床科室检查报告'},
                    {Key:3,Title:'实验室检验报告'},
                    {Key:4,Title:'影像检查报告'},
                    {Key:5,Title:'仪器原始报告（如人体成份分析仪、骨密度、肺活量等），包括不支持输出的仪器'},
                ]


            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .HomeProduct{
        background-color: transparent !important;
    }
    .product{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        // display: flex;       
    }
    .productcard{
        height:200px;
        width:200px;
        border:1px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        border-radius: 4px;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        background-color: white;
    }
    .producticon{
        width:100%;
        height:48px;
        margin-top:50px;
    }
    .producttitle{
        text-align: center;
        font-size:16px;
        line-height:80px;
        font-weight: 500;
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
    .text ul {
        display: flex;
        flex-wrap: wrap;
    }
    .text li {
        align-items: center;
        flex-basis: 100%;    
    }        
</style>


