<template>
    <div class="HomeProduct">    
        <div style="height:60px;display:flex;">
            <div style="width:48px;height:48px;">
                <el-image fit="scale-down" :src="require('@/images/48/dku.03202.png')"/>
            </div>
            <div style="font-size:22px;font-weight:500;line-height:48px;margin-left:10px;color:#000099;">
                <span>健康体检中心重点功能</span>
            </div>            
        </div>  
        <div class="product">
            <div v-for="row in products" :key="row.Key" class="row">
                <div style="display:flex;">
                    <div v-for="item in row.Items" :key="item.Key" class="productcard">
                        <div style="min-width:128px;">
                            <div class="producticon">

                                <Macmonitor style="width:151px;height:120px;margin-top:15px;"
                                    :left="6" 
                                    :top="6" 
                                    :width="139" 
                                    :height="82"
                                    :image="item.Image"
                                />  
                            </div>
                        </div> 
                        <div style="width:240px;font-size:9px;margin-left:15px;">
                            <div class="producttitle">
                                <span>{{item.Title}}</span>
                            </div>                            
                            <ul>
                                <li v-for="txt in item.texts" :key="txt.Key">
                                    <span style="color:rgb(50,50,50);">{{txt.Title}}</span>                                    
                                </li>                 
                            </ul>  
                        </div>                       

                    </div>
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    import Macmonitor from "@/views/template/Macmonitor";  
    export default {    
        //##############################################################################################################################
        components:
        {
            Macmonitor
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Items:[
                                {Key:1,Title:'单位体检申请',Image:require('@/images/free/dku.00106.png'),texts:[
                                        {Key:1,Title:'直接申请'},
                                        {Key:2,Title:'接受单位健管中心的申请'},
                                        {Key:3,Title:'体检类型及参数等约定'}
                                        ]},
                                {Key:2,Title:'单位体检安排',Image:require('@/images/free/dku.00109.png'),texts:[
                                    {Key:1,Title:'安排体检具体日期及范围'},
                                    {Key:2,Title:'受检者通过个人健管中心自助预约'},
                                    {Key:3,Title:'受检单位通过单位健管中心辅助预约'}
                                    ]},
                                {Key:3,Title:'个人检前咨询',Image:require('@/images/free/dku.00106.png'),texts:[
                                    {Key:1,Title:'受检者检前问卷'},
                                    {Key:2,Title:'根据问卷推荐体检项目'},
                                    {Key:3,Title:'人为调整体检项目'}
                                    ]},
                                ]
                    },
                    {Key:2,Items:[                    
                                {Key:4,Title:'个人早餐登记',Image:require('@/images/free/dku.00106.png'),texts:[
                                    {Key:1,Title:'早餐签到登记'},
                                    {Key:2,Title:'餐前项目未做提醒'}
                                    ]},                         
                                {Key:5,Title:'个人危急报警',Image:require('@/images/free/dku.00108.png'),texts:[
                                    {Key:1,Title:'根据规则自动识别报警'},
                                    {Key:2,Title:'医师人工识别报警'},
                                    {Key:3,Title:'报警处理:忽略、误报、复查、门诊就诊等'}
                                    ]},      
                                {Key:6,Title:'个人分检核查',Image:require('@/images/free/dku.00106.png'),texts:[
                                    {Key:1,Title:'补录个别体检项目结果'},
                                    {Key:2,Title:'纠正常规性医学描述错误'},
                                    {Key:3,Title:'了解未检项目原因并记录'}
                                    ]},                    
                                ]
                    },
                    {Key:4,Items:[                                                    
                                {Key:6,Title:'个人总检评估',Image:require('@/images/free/dku.00106.png'),texts:[
                                    {Key:1,Title:'分检结论汇总'},
                                    {Key:2,Title:'重新评估总检结论'},
                                    {Key:3,Title:'个人体检分析并结论'},
                                    {Key:4,Title:'职业病及从业体检合格判定'},
                                    {Key:5,Title:'多级审核避免误诊结论'}
                                    ]},                  
                                {Key:7,Title:'单位总检评估',Image:require('@/images/free/dku.00107.png'),texts:[
                                    {Key:1,Title:'汇总合并单位受检者结论及人数'},
                                    {Key:2,Title:'单位整体多角度健康状况分析（如年龄段分析、代谢性疾病分析等）'}
                                    ]},                                    
                                {Key:8,Title:'个人检后咨询',Image:require('@/images/free/dku.00106.png'),texts:[
                                    {Key:1,Title:'个人报告咨询及解读'},
                                    {Key:2,Title:'解读结果处理措施建议'}
                                    ]},      
                                ]
                    }                                        
                ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1060px;        
        // margin-left:auto;
        // margin-right:auto; 
        // display: flex;      
        border-top:1px solid #0072C6;
    }
    .row{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;     
        border-top:0px solid #0072C6;      
    }
    .productcard{
        height:150px;
        width:330px;
        border:0px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        margin-left:7px;
        margin-top:30px;
        padding:5px;
        border-radius: 4px;
        display: flex;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        background-color: rgba(0, 0, 255, 0.2);
        
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:160px;
        margin-top:0px;
    }
    .producttitle{
        text-align: center;
        font-size:14px;
        line-height:30px;
        font-weight: 600;
        color:rgb(80,80,80);
        
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
</style>