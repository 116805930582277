<template>
    <div class="Home">
        <Scrollbar>
            <div class="content">
                <div>
                    <div v-for="block in blocks" :key="block.Key">
                        <div v-if="block.Key==1" :style="block.Style">
                            <ExamineSolution/>
                        </div>                        
                        <div v-else-if="block.Key==2" :style="block.Style">
                            <ExamineHospital/>
                        </div>
                        <div v-else-if="block.Key==3" :style="block.Style"> 
                            <ExamineType/>
                        </div>
                        <div v-else-if="block.Key==4" :style="block.Style">
                            <ExamineImportant/>
                        </div>
                        <div v-else-if="block.Key==5" :style="block.Style">    
                            <ExamineReport/>
                        </div>
                        <div v-else-if="block.Key==6" :style="block.Style">    
                            <ExaminePerson/>
                        </div>
                        <div v-else-if="block.Key==7" :style="block.Style">    
                            <ExamineCompany/>
                        </div>
                    </div>
                </div>
            </div>     
            <div>
                <SimpleConcat/>
            </div>
        </Scrollbar>
    </div>
</template>

<script>    
    import FrameContainer from "@/components/base/frame/FrameContainer"; 
    import Scrollbar from "@/components/base/Scrollbar";  
    import SimpleConcat from "@/views/template/SimpleConcat";  
    import ExamineSolution from "@/views/examine/ExamineSolution";
    import ExamineType from "@/views/examine/ExamineType";
    import ExamineHospital from "@/views/examine/ExamineHospital";
    import ExamineImportant from "@/views/examine/ExamineImportant";
    import ExamineReport from "@/views/examine/ExamineReport";
    import ExaminePerson from "@/views/examine/ExaminePerson";
    import ExamineCompany from "@/views/examine/ExamineCompany";
    export default {            
        //##############################################################################################################################
        components:
        {
            FrameContainer,Scrollbar,SimpleConcat,
            ExamineType,ExamineSolution,ExamineHospital,ExamineImportant,ExamineReport,ExaminePerson,ExamineCompany
        },    
        //############################################################################################################################## 
        data(){
            return{   
                blocks:[                    
                    {Key:1,Title:'健康体检中心产品解决方案',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:30px;padding-bottom:60px;"},
                    {Key:2,Title:'医院体检中心产品解决方案',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;display:flex;"},
                    {Key:3,Title:'产品支持的体检类型',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;"},
                    {Key:4,Title:'健康体检中心重点功能',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;display:flex;"},
                    {Key:5,Title:'受检者体检报告组成',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;display:flex;"},
                    {Key:6,Title:'个人健管中心',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;"},
                    {Key:7,Title:'单位健管中心',Style:"background:rgb(255,255,255,0.5);margin-top:0px;padding:20px;padding-top:60px;padding-bottom:60px;display:flex;"}
                ]
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .Home{
        height:100%;
        width:100%;
        background-color: rgba(255, 255, 255, 0.1); 
        padding-top:5px;                       
    }
    .content{
        margin-left:auto;
        margin-right:auto;  
        width:1100px;
        text-align: left;     
    }
</style>