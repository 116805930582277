<template>
    <div class="HomeProduct">                           
        <div style="height:60px;display:flex;">
            <div style="width:48px;height:48px;">
                <el-image fit="scale-down" :src="require('@/images/48/dku.02832.png')"/>
            </div>
            <div style="font-size:22px;font-weight:500;line-height:48px;margin-left:10px;color:#000099;">
                <span>医院体检中心产品解决方案</span>
            </div>            
        </div>  

        <div class="product">
            <div style="width:1060px;height:750px;border-top:1px solid #0072C6;">
                <div style="height:712px;width:694px;margin-top:55px;margin-left:203px;">
                    <el-image fit="scale-down" :src="require('@/images/free/dku.00119.png')" style="width:100%;height:100%;border-radius:6px;"/>
                </div>                
            </div>
        </div>    
                                 
    </div>
</template>

<script>      
    export default {    
        //##############################################################################################################################
        components:
        {
            
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Title:'健康体检中心',Icon:'20308'},                
                    {Key:3,Title:'个人健管中心',Icon:'200188'},
                    {Key:4,Title:'单位健管中心',Icon:'200229'},
                    {Key:5,Title:'产品管理中心',Icon:'20048'},
                    {Key:2,Title:'数据交换平台',Icon:'10091'},
                ],
                texts:[
                    {Key:1,Title:'健康体检中心的基础数据（如检验检查项目、工作人员、部门科室等）保持和院内医院HIS系统同步，无需重复维护。'},
                    {Key:2,Title:'健康体检的检验检查申请支持由LIS和PACS执行并自动回传报告。'},
                    {Key:3,Title:'受检者个人体检费用明细自动同步到院内HIS，允许由HIS窗口完成结算并回传结算状态。'},
                    {Key:4,Title:'体检中心与院内系统之间采用数据交换平台接入，集中管理。'},
                    {Key:5,Title:'体检中心受检者微信端支持直接挂入院内统一的微信小程序。'},
                ]


            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .HomeProduct{
        background-color: transparent !important;
    }
    .product{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;       
    }
    .productcard{
        height:200px;
        width:200px;
        border:1px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        border-radius: 4px;
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
        background-color: white;
    }
    .producticon{
        width:100%;
        height:48px;
        margin-top:50px;
    }
    .producttitle{
        text-align: center;
        font-size:16px;
        line-height:80px;
        font-weight: 500;
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
    .text ul {
        display: flex;
        flex-wrap: wrap;
    }
    .text li {
        align-items: center;
        flex-basis: 100%;    
    }        
</style>


