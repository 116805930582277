<template>
    <div class="HomeProduct">    
        <div class="product">
            <div v-for="row in products" :key="row.Key" class="row">
                <div style="display:flex;">
                    <div v-for="item in row.Items" :key="item.Key" class="productcard">
                        <div style="min-width:600px;">
                            <div class="producticon">
                                <div style="margin-left:130px;">
                                    <Macmonitor style="width:788px;height:600px;"
                                        :left="30" 
                                        :top="31" 
                                        :width="728" 
                                        :height="409"
                                        :image="require('@/images/free/dku.00105.png')"
                                    />
                                </div>
                            </div>
                        </div> 
                        <div style="width:780px;font-size:14px;line-height:30px;margin-left:120px;margin-top:60px;">
                            <ul>
                                <li v-for="txt in item.texts" :key="txt.Key">
                                    <span style="color:rgb(40,40,40);">{{txt.Title}}</span>                                    
                                </li>                 
                            </ul>  
                        </div>                       

                    </div>
                </div>
            </div>
        </div>                               
    </div>
</template>

<script>      
    import Macmonitor from "@/views/template/Macmonitor";  
    export default {    
        //##############################################################################################################################
        components:
        {
            Macmonitor
        },    
        //############################################################################################################################## 
        data(){
            return{   
                products:[
                    {Key:1,Items:[
                                {Key:1,Title:'单位体检申请',Icon:'20308',texts:[
                                        {Key:1,Title:'不管是接入新的三方系统还是调整已有系统的接口只需通过平台的服务配置完成，无须开发人员重新编写程序'},
                                        {Key:2,Title:'交换过程中传输的数据允许选择是否采取RSA非对称加密传输，防止被非法拦截获取'},
                                        {Key:3,Title:'提供给三方调用的服务同时支持Webapi方式和WebService方式，满足不同软件商的要求'},
                                        {Key:4,Title:'数据平台是一个容器，允许将不同的第三方系统注册为平台应用，完成应用之间的数据交换配置'},
                                        ]}
                                ]
                    }               
                ]               
            }
        }, 
        //##############################################################################################################################
        methods:
        {

                        
        },   
    }
</script>

<style lang="scss" scoped>
    .product{
        width: 1060px;        
        // margin-left:auto;
        // margin-right:auto; 
        // display: flex;      
        // border-top:1px solid #0072C6;
    }
    .row{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto; 
        display: flex;     
        border-top:0px solid #0072C6;      
    }
    .productcard{
        height:700px;
        width:1030px;
        border:0px solid rgba(128, 128, 128, 0.3);
        margin:5px;
        margin-left:7px;
        margin-top:30px;
        padding:5px;
        border-radius: 4px;  
        
    }
    .producticon{
        // margin-left:auto;
        // margin-right:auto; 
        width:100%;
        height:600px;
        margin-top:0px;
    }
    .producttitle{
        text-align: center;
        font-size:14px;
        line-height:30px;
        font-weight: 600;
        color:rgb(80,80,80);
        
    }
    .productbutton{
        font-size:9px;
        border:1px solid rgba(128, 128, 128, 0.8);
        border-radius:20px;
        width:70px;
        line-height:28px;
        text-align:center;
        margin-left:65px;
        cursor: pointer;
        color:grey;
        // margin-right:50px;
    }
</style>