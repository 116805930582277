export default
{   
    //==========================================================================================================================
    //空值处理（常用）
    StringNvl(element)
    {
        if(element==null) return("");
        return(element);
    },    
    //==========================================================================================================================
    // 是否为空
    IsEmpty(val) {
        for (let key in val) 
        {
            return false
        }
        return true
    },    
    //==========================================================================================================================
    ReadLocalFile(fileUrl)
    {        
        let xhr = null
        if (window.XMLHttpRequest) 
        {
            xhr = new XMLHttpRequest()
        }
        else 
        {      
            xhr = new ActiveXObject('Microsoft.XMLHTTP')
        }
        const okStatus = document.location.protocol === 'file'? 0 : 200
        xhr.open('GET',fileUrl,false)
        xhr.overrideMimeType('text/html;charset=utf-8')
        xhr.send(null)
        return(xhr.status === okStatus ? xhr.responseText : null)
    },

    //==========================================================================================================================
    //格式化日期
    FormatDate: function(value,args)
    {
        var dt = new Date(value);
        if(args == 'yyyy-M-d') 
        {
            let year = dt.getFullYear();
            let month = dt.getMonth() + 1;
            let date = dt.getDate();
            return `${year}-${month}-${date}`;
        } 
        else if(args == 'yyyy-M-d H:m:s')
        {
            let year = dt.getFullYear();
            let month = dt.getMonth() + 1;
            let date = dt.getDate();
            let hour = dt.getHours();
            let minute = dt.getMinutes();
            let second = dt.getSeconds();
            return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
        } 
        else if(args == 'yyyy-MM-dd') 
        {
            let year = dt.getFullYear();
            let month = (dt.getMonth() + 1).toString().padStart(2,'0');
            let date = dt.getDate().toString().padStart(2,'0');
            return `${year}-${month}-${date}`;
        } 
        else if(args == 'yyyy-MM-dd HH:mm:ss') 
        {
            let year = dt.getFullYear();
            let month = (dt.getMonth() + 1).toString().padStart(2,'0');
            let date = dt.getDate().toString().padStart(2,'0');
            let hour = dt.getHours().toString().padStart(2,'0');
            let minute = dt.getMinutes().toString().padStart(2,'0');
            let second = dt.getSeconds().toString().padStart(2,'0');
            return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
        }
    },   
    //==========================================================================================================================
    GetMonday(type, dates) 
    {
        var now = new Date();
        var nowTime = now.getTime();
        var day = now.getDay();
        if(day==0) day=7;
        var longTime = 24 * 60 * 60 * 1000;
        var n = longTime * 7 * (dates || 0);
        if (type == "s") {
            var dd = nowTime - (day - 1) * longTime + n;
        };
        if (type == "e") {
            var dd = nowTime + (7 - day) * longTime + n;
        };
        dd = new Date(dd);
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1;
        var d = dd.getDate();
        m = m < 10 ? "0" + m: m;
        d = d < 10 ? "0" + d: d;
        var day = y + "-" + m + "-" + d;
        return day;
    },
    //==========================================================================================================================
    DateAddDays(base,days) 
    {
        let odata = new Date(new Date(base).getTime() + days * 24 * 60 * 60 * 1000); //计算当前日期 -1
        return(this.ConvertToDate(odata)); //格式化日期并赋值
    }, 
    //==========================================================================================================================
    DateAddSeconds(base,seconds) 
    {
        let odata = new Date(new Date(base).getTime() + seconds * 1000); //计算当前日期 -1
        return(this.FormatDate(odata,"yyyy-MM-dd HH:mm:ss")); //格式化日期并赋值
    }, 
    //==========================================================================================================================
    GetDiffDays(start,stop)
    {
        let days,diffdate;
        let startdate=Date.parse(start);
        let stopdate=Date.parse(stop);
        diffdate=Math.abs(startdate - stopdate);
        days=Math.floor(diffdate/(1000*3600*24));
        return(days);
    }, 
    //==========================================================================================================================
    ConvertToDate(date) 
    {
        var date = new Date(date);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        m = m < 10 ? "0" + m : m; //月小于10，加0
        d = d < 10 ? "0" + d : d; //day小于10，加0
        return y + "-" + m + "-" + d;
    },
    //==========================================================================================================================
    Between(target,sourcestart,sourcestop)
    {
        if(target>=sourcestart && target<=sourcestop) return(true);
        return(false);
    },    
    //==========================================================================================================================
    S4() 
    {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    },
    //==========================================================================================================================
    Guid() 
    {
        var g=this.S4()+this.S4()+this.S4()+this.S4()+this.S4()+this.S4()+this.S4()+this.S4();
        
        return (g.toUpperCase());
    },
    //==========================================================================================================================
    ToBase64String(source)
    {
        if(source=="") return(source);
        let Base64 = require('js-base64').Base64;
        var target=Base64.encode(source);
        return(target);
    },
    //==========================================================================================================================
    FromBase64String(source)
    {
        if(source=="") return(source);
        let Base64 = require('js-base64').Base64;
        var target=Base64.decode(source);
        return(target);
    },
    //==========================================================================================================================
    GetMessageBoxTitle()
    {
        return("德数云健康");
    },
    //==========================================================================================================================
    CreateCommand(kind)
    {
        var cmd=kind+'_'+this.Guid();
        return(cmd);
    },
    //==========================================================================================================================
    CopyToolbar(sourcetoolbar)
    {
        var toolbar=[];
        for(var i in sourcetoolbar)
        {
            var item={key:0,title:'',mark:'',itype:'',icon:'',type:'',enabled:true,hidden:false,placement:'',desc:'',children:[],begingroup:false};
            item.key=sourcetoolbar[i].key;
            item.title=sourcetoolbar[i].title;
            item.mark=sourcetoolbar[i].mark;
            item.itype=sourcetoolbar[i].itype;
            item.icon=sourcetoolbar[i].icon;
            item.type=sourcetoolbar[i].type;
            item.enabled=sourcetoolbar[i].enabled;
            item.hidden=sourcetoolbar[i].hidden;
            item.placement=sourcetoolbar[i].placement;
            item.desc=sourcetoolbar[i].desc;
            item.begingroup=sourcetoolbar[i].begingroup;
            item.children=null;
            if(sourcetoolbar[i].children!=null)
            {
                if(sourcetoolbar[i].children.length>0)
                {                            
                    item.children=this.CopyToolbar(sourcetoolbar[i].children);
                }
            }
            toolbar.push(item);
        }
        return(toolbar);
    },    
    //==========================================================================================================================
    GetToolbarItemByKey(items,key)                                                                         
    {
        for(var i in items)
        {
            if(items[i].key==key) return(items[i]);
        }
        return(null);
    },      
    //==========================================================================================================================
    GetToolbarItemByTitle(items,title)                                                                         
    {
        for(var i in items)
        {
            if(items[i].title==title) return(items[i]);
        }
        return(null);
    },          
    //==========================================================================================================================
    GetToolbarItemByMark(items,mark)                                                                         
    {
        for(var i in items)
        {
            if(items[i].mark==mark) return(items[i]);
        }
        return(null);
    },       
    //==========================================================================================================================
    UpdateToolbarItemByMarks(items,enabled,marks)
    {
        for(var i in marks)
        {
            for(var j in items)
            {
                if(marks[i]==items[j].mark) 
                {
                    items[j].enabled=enabled;
                    break;
                }
                if(items[j].children!=null)
                {
                    for(var c in items[j].children)
                    {
                        if(marks[i]==items[j].children[c].mark) 
                        {
                            items[j].children[c].enabled=enabled;
                            break;
                        }                    
                    }
                }

            }
            
        }
        return(null);
    },           
    //==========================================================================================================================
    GetEnvironmentData()
    {
        var item=
        {
            HecKey:'',UserCode:'',UserName:'',Logo:'',StationCode:'',StationTitle:'',MsgBoxTitle:'德数云健康',LogoTitle:'德数云健康',
            Device:{Kind:1,IP:'',},
            grayperiod:{Start:null,Stop:null},
            today:{Start:'',Stop:''},
            authoritys:[],
            createexamine:{}
        };        
        item.HecKey=sessionStorage.getItem('heckey',"FBC2147EE2E44FCFA8286194F52F8F25");
        item.UserCode=sessionStorage.getItem('usercode',"");
        item.UserName=sessionStorage.getItem('username',""); 
        item.Logo=sessionStorage.getItem('userlogo',"");
        item.StationCode="50.002";
        item.StationTitle="重庆观音桥离退休干部体检中心";
        item.Device.Kind=1;        
        item.Device.IP=sessionStorage.getItem('clientipaddress',"");
        item.today.Start=this.FormatDate(new Date(),'yyyy-MM-dd')+" 00:00:00";
        item.today.Stop=this.FormatDate(new Date(),'yyyy-MM-dd')+" 23:59:59";
        item.grayperiod.Start=new Date('2024-03-20 00:00:00');
        item.grayperiod.Stop=new Date('2024-05-30 12:59:59');
        item.createexamine={defaultdoctor:{Key:'42409F68619B4FC4BC7DACA0C0383E2B',Title:'亓会军'}};

        var accountroute = sessionStorage.getItem("accountroute","");        
        if(accountroute!='')
        {
            var routes=JSON.parse(accountroute);            
            for(var i in routes)
            {                
                for(var j in routes[i].children)
                {                    
                    var func={Key:'',Functions:''};
                    func.Key=routes[i].children[j].name;
                    func.Functions=routes[i].children[j].meta.functions;
                    item.authoritys.push(func);
                }
            }
        }        
        return(item);
    },
    //==========================================================================================================================
    ExistAuthority(source,key,target)
    {
        target="," + target + ",";
        for(var i in source)
        {
            if(source[i].Key==key)
            {                
                var funcs=source[i].Functions;
                var pos=funcs.indexOf(target);
                if(pos>=0) return(true);
                return(false);                
            }
        }
    },
    //==========================================================================================================================
    RemoveToolbarFirstSplit(toolbaritems)
    {
        var index=0;
        for(var i in toolbaritems)
        {
            if(toolbaritems[i].hidden==false)
            {
                index++;
                if(index==1)
                {
                    if(toolbaritems[i].begingroup==true) toolbaritems[i].begingroup=false;
                    break;
                }
            }
        }        
    }, 
    //==========================================================================================================================
    fetchClientIP()
    {
        
    },
    //==========================================================================================================================
    isMobile()
    {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return(flag);
    }
}